import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };
  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='PREMIUM HEALTHCARE PLANS IN NEW YORK' activeCard='new-york' image={'/New-York_BG.jpeg'}>
          <p>
            Apollo Insurance partners with quality healthcare providers in New York, including Aliera, Aetna, Cigna, National General, and Blue Cross Blue
            Shield. Our alliances with premium New York insurance providers allow us to accomplish our goal of affording clients the best medical benefits at
            the lowest cost for their lifestyle. Apollo Insurance provides customized insurance plans for New York residents through a mixture of Affordable
            Care Act plans, Short Term Medical Plans, Share Ministry Plans and other off-exchange policies. Our experts understand that insurance is expensive,
            which is why we have picked these healthcare providers in New York, for their low-cost, premium coverage, and the security and peace of mind you
            deserve.
          </p>

          <span>Health insurance in New York</span>

          <p>
            Apollo Insurance’s client commitment is supported with the understanding that insurance solutions are not “one-size fits all”. For that reason, we
            offer packages that support group insurance, individual insurance, life insurance, and other medical benefits. Unpredictable moments happen and we
            want to make sure New Yorkers are covered with a comprehensive portfolio that ensures co-workers, friends, and family receive the benefits they need
            when they need it most.
          </p>

          <p>Trust us to discover the insurance solution that fits you best. Contact us today for a free quote with healthcare providers in New York.</p>
        </Content>
      </div>
    </Layout>
  );
};
